import { CommonModule } from '@angular/common';
import { Component, ViewChild, inject, signal } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { showAlertMessage } from '@app/core/helpers/utils';
import { PipesModule } from '@app/core/pipes/pipes.module';
import { LeagueService } from '@app/core/services/league.service';
import { LocalStorageService } from '@app/core/services/local-storage.service';
import { Store } from '@ngrx/store';
import { StripeElementsOptions, StripePaymentElementOptions } from '@stripe/stripe-js';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  StripeElementsDirective,
  StripePaymentElementComponent,
  injectStripe
} from 'ngx-stripe';

@Component({
  selector: 'app-league-card-payment',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    StripeElementsDirective,
    StripePaymentElementComponent,
    CommonModule,
    PipesModule
  ],
  templateUrl: './league-card-payment.component.html',
  styleUrl: './league-card-payment.component.scss'
})
export class LeagueCardPaymentComponent {

  @ViewChild(StripePaymentElementComponent)
  paymentElement!: StripePaymentElementComponent;
  isLoading = false;

  constructor(private leagueService: LeagueService,
    private ngxSpinnerService: NgxSpinnerService,
    private router: Router,
    private localStorageService: LocalStorageService,
  ) {

  }
  stripePaymentObj: any = this.localStorageService.getSaveData('leaguePaymentData');
  elementsOptions!: StripeElementsOptions;
  setupIntent: any;
  store = inject(Store)?.select('layout').subscribe((data: any) => {
    this.elementsOptions = {
      locale: 'en',
      clientSecret: this.stripePaymentObj?.clientSecret,
      appearance: {
        theme: data.LAYOUT_MODE === 'dark' ? 'night' : 'stripe',
      },
    }
  });

  paymentElementOptions: StripePaymentElementOptions = {
    layout: {
      type: 'tabs',
      defaultCollapsed: false,
      radios: false,
      spacedAccordionItems: false,
    },
  };
  stripe = injectStripe(this.stripePaymentObj?.publishableKey);
  paying = signal(false);
  pay() {
    this.isLoading = true;
    if (this.paying()) return;
    this.paying.set(true);
    this.ngxSpinnerService.show();
    this.stripe.confirmSetup({
      elements: this.paymentElement.elements,
      redirect: 'if_required'
    })
      .subscribe((result: any) => {
        this.paying.set(false);
        this.ngxSpinnerService.show();
        if (result.error) {
          // Show error to your customer (e.g., insufficient funds)
          this.ngxSpinnerService.hide();
          this.isLoading = false;
          showAlertMessage(result.error.message ?? "Something went wrong!", "error");
        } else {
          // The payment has been processed!
          this.setupIntent = result.setupIntent;
          if (result.setupIntent.status === 'succeeded') {
            this.registerTeam();
          }
        }
      });
  }

  registerTeam() {
    const payload = {
      "setupId": this.setupIntent.id,
      "customerId": this.stripePaymentObj.customerId,
      "clientSecret": this.setupIntent.client_secret,
      "paymentMethod": this.setupIntent.payment_method,
      "teamId": this.stripePaymentObj?.teamId,
      "leagueId": this.stripePaymentObj?.leagueId,
      "teamFee": this.stripePaymentObj?.teamFee,
      "totalAmountPerRoster": this.stripePaymentObj?.totalAmountPerRoster,
      "totalRoster": this.stripePaymentObj?.totalRoster,
      "ageGroup": this.stripePaymentObj?.ageGroup,
      "divisionId": this.stripePaymentObj?.divisionId,
      "athlete": this.stripePaymentObj?.athlete, //static
      "serviceFee": this.stripePaymentObj?.serviceFee,
      "transactionId": this.stripePaymentObj?.transaction_id,
      "totalCost": this.stripePaymentObj?.totalCost,
      "email": this.stripePaymentObj?.email,
    }

    this.ngxSpinnerService.show();
    this.leagueService.leaguePayment(payload).subscribe((response: any) => {
      this.isLoading = false;
      this.ngxSpinnerService.hide();
      if (response?.status) {
        localStorage.removeItem('leaguePaymentData');
        showAlertMessage(response?.message ?? "Event Registration Completed Successfully", "success", () => {
          this.router.navigate(['/account-login']);
        });
      } else {
        this.isLoading = false;
        showAlertMessage(response?.message ?? "Something went wrong!", "error");
        this.ngxSpinnerService.hide();
      }
    }, () => {
      this.isLoading = false;
      this.ngxSpinnerService.hide();
    });
  }
}
