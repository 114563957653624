import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';

import { NgxSpinnerService } from 'ngx-spinner';
import { TokenStorageService } from '../services/token-storage.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private token: TokenStorageService,
    private ngxSpinnerService: NgxSpinnerService
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.token.getToken();
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    return next.handle(request).pipe(
      catchError((error: any) => {
        if (error?.status === 401 || error === 'Expired Token' || error === 'Invalid token') {
          this.ngxSpinnerService.hide()
          this.token.signOut(); // Log out the user
        }
        return throwError(error); // Propagate the error
      })
    );
  }
}
