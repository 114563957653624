<body
  class="flex min-h-screen bg-slate-50 dark:bg-zink-800 dark:text-zink-100 font-public">
  <div class="flex w-full justify-center">
    <div class="flex items-center">
      <div
        class="mb-0 lg:mx-auto w-[320px] sm:w-[410px] card shadow-none border-none !bg-transparent h-fit">
        <div class="!px-10 !py-12 card-body">
          <div
            class="flex flex-col items-center justify-center text-center mb-4">
            <span
              class="group-data-[sidebar-size=sm]:hidden font-kanit text-3xl md:text-4xl lg:text-4xl xl:text-4xl font-bold italic text-white mb-6">
              SKETCHPLAY
            </span>

            <ng-container *ngIf="steps === 0">
              <span class="text-[#94A3B8]">
                Fill out the information below to register with Sketchplay.
              </span>
            </ng-container>

            <ng-container *ngIf="steps === 1">
              <span class="text-[#94A3B8] text-[14px]">
                Choose a password below. We recommend you use a combination of
                letters, numbers, symbols, and ensure it is at least 8
                characters long.
              </span>
            </ng-container>

            <ng-container *ngIf="steps === 2">
              <span class="text-[#94A3B8]">
                To confirm your account, please enter the security code. We sent
                this to both your email and mobile number.
              </span>
            </ng-container>

            <ng-container *ngIf="steps === 3">
              <span class="text-[#94A3B8]">
                Choose your primary role.. Don’t worry, you will have the
                ability to manage this in your profile settings area.
              </span>
            </ng-container>
          </div>

          <ng-container *ngIf="steps === 0">
            <form
              action=""
              class="mt-10"
              id="signInForm"
              [formGroup]="signUpForm"
              (ngSubmit)="onSubmit()">
              <div
                class="hidden px-4 py-3 mb-3 text-sm text-green-500 border border-green-200 rounded-md bg-green-50 dark:bg-green-400/20 dark:border-green-500/50"
                id="successAlert">
                You have <b>successfully</b> signed in.
              </div>
              <div class="mb-5">
                <input
                  type="text"
                  id="firstName"
                  formControlName="firstName"
                  autocomplete="off"
                  class="form-input border border-input-border-color bg-input-bg-color color-['#94A3B8'] text-[#94A3B8] py-3"
                  placeholder="Enter your first name..." />
                  <div
                  *ngIf="
                    signUpForm.get('firstName')?.invalid &&
                    (signUpForm.get('firstName')?.dirty ||
                      signUpForm.get('firstName')?.touched)
                  "
                  class="invalid-feedback">
                  <div
                    *ngIf="signUpForm.get('firstName')?.errors?.['required'] || signUpForm.get('firstName')?.errors?.['pattern']"
                    class="text-red-500 ml-1 mt-1">
                    First name is required.
                  </div>
                </div>
              </div>

              <div class="mb-5">
                <input
                  type="text"
                  id="lastName"
                  formControlName="lastName"
                  autocomplete="off"
                  class="form-input border border-input-border-color bg-input-bg-color color-['#94A3B8'] text-[#94A3B8] py-3"
                  placeholder="Enter your last name..." />
                <div
                  *ngIf="
                    signUpForm.get('lastName')?.invalid &&
                    (signUpForm.get('lastName')?.dirty ||
                      signUpForm.get('lastName')?.touched)
                  "
                  class="invalid-feedback">
                  <div
                    *ngIf="signUpForm.get('lastName')?.errors?.['required'] || signUpForm.get('lastName')?.errors?.['pattern']" 
                    class="text-red-500 ml-1 mt-1">
                    Last name is required.
                  </div>
                </div>
              </div>

              <div class="mb-5">
                <input
                  type="text"
                  formControlName="email"
                  autocomplete="off"
                  class="form-control form-input border border-input-border-color bg-input-bg-color color-['#94A3B8'] text-[#94A3B8] py-3"
                  placeholder="Enter your email..." />

                <div
                  *ngIf="
                    signUpForm.get('email')?.invalid &&
                    (signUpForm.get('email')?.dirty ||
                      (signUpForm.get('email')?.touched && isFormSubmitted))
                  "
                  class="invalid-feedback">
                  <div
                    *ngIf="signUpForm.get('email')?.errors?.['required']"
                    class="text-red-500 ml-1 mt-1">
                    Email is required.
                  </div>
                </div>
                <div
                  *ngIf="
                    signUpForm.get('email')?.invalid &&
                    signUpForm.get('email')?.dirty &&
                    isFormSubmitted
                  "
                  class="invalid-feedback">
                  <div
                    *ngIf="signUpForm.get('email')?.errors?.['pattern']"
                    class="text-red-500 ml-1 mt-1">
                    Please enter correct email address.
                  </div>
                </div>
              </div>

              <div class="relative">
                <div class="mb-5">
                  <input
                    type="text"
                    formControlName="mobile"
                    id="mobile"
                    autocomplete="off"
                    class="form-input border border-input-border-color bg-input-bg-color text-[#94A3B8] py-3"
                    placeholder="Enter your mobile number..."
                    mask="(000) 000-0000"
                    [showMaskTyped]="true"
                    [dropSpecialCharacters]="false" />

                  <div
                    *ngIf="
                      signUpForm.get('mobile')?.invalid &&
                      (signUpForm.get('mobile')?.dirty ||
                        (signUpForm.get('mobile')?.touched && isFormSubmitted))
                    "
                    class="invalid-feedback">
                    <div
                      *ngIf="signUpForm.get('mobile')?.errors?.['required']"
                      class="text-red-500 ml-1 mt-1">
                      Mobile number is required.
                    </div>
                  </div>
                  <div
                    *ngIf="
                      signUpForm.get('mobile')?.invalid &&
                      signUpForm.get('mobile')?.dirty &&
                      isFormSubmitted
                    "
                    class="invalid-feedback">
                    <div
                      *ngIf="signUpForm.get('mobile')?.errors?.['pattern']"
                      class="text-red-500 ml-1 mt-1">
                      Invalid mobile number
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-5 flex">
                <div class="mr-2">
                  <input type="checkbox" class="rounded-checkbox" id="checkbox1" [checked]="isTermsandCondition" (change)="onCheckboxChange($event)" />
                </div>
                <label for="checkbox1" class="inline-flex items-center text-xs">
                  <p class="text-[#94A3B8]">By checking this box, you agree to digital sign and agree to the Sketchplay <a 
                    href="https://app.termly.io/policy-viewer/policy.html?policyUUID=8ff5d49b-f0b5-4f25-9b05-392287331111" 
                    target="_blank" 
                    class="font-semibold text-white underline">
                    terms and conditions
                  </a>.  </p>
                </label>
              </div>
              <div
              *ngIf="showErrorofTerms"
              class="text-red-500 ml-1 mt-1">
              You must agree to the terms and conditions of the Sketchplay platform
            </div>

              <div class="mt-12">
                <button
                class="w-full text-[#C9D8E9] font-normal btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                  (click)="goToStep1()">
                  {{ submitted ? 'Loading...' : 'Choose a password' }}
                </button>
              </div>
            </form>
          </ng-container>

          <ng-container *ngIf="steps === 1">
            <app-sign-up-step1
              [userDetail]="userDetail"
              (stepValue)="step($event)"></app-sign-up-step1>
          </ng-container>

          <ng-container *ngIf="steps === 2">
            <app-sign-up-step2 (stepValue)="step($event)"></app-sign-up-step2>
          </ng-container>

          <!-- <ng-container *ngIf="steps === 3">
            <app-sign-up-step3 (stepValue)="step($event)"></app-sign-up-step3>
          </ng-container> -->

          <div class="mt-8 text-center">
            <span *ngIf="steps === 0">
              <p class="mb-7 text-[12px] text-[#C9D8E9]">
                Already Registered ? <br />
                <a
                  (click)="navigateToLogin()"
                  class="font-bold transition-all cursor-pointer duration-150 ease-linear text-[#C9D8E9]"
                  >Sign in Here</a
                >
              </p>
            </span>
            <p class="text-[#C9D8E9] text-[12px] mt-28">
              © 2024 Crafted with
              <i class="ri-heart-fill heart-icon"></i> by Lab3, Interactive,
              inc.
              <br />
              DBA as
              <a
                href="https://sketchplay.com"
                target="_blank"
                class="font-semibold underline transition-all duration-150 ease-linear dark:text-zink-200 dark:hover:text-custom-500">
                Sketchplay.
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</body>
