import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '@app/account/login/login.service';
import { showAlertMessage } from '@app/core/helpers/utils';
import { UserdataService } from '@app/core/services/userdata.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NgxSpinnerService } from 'ngx-spinner';
import { of } from 'rxjs';
import { catchError, exhaustMap, tap } from 'rxjs/operators';
import { login, loginFailure, loginSuccess, logout, logoutSuccess } from './authentication.actions';
@Injectable()
export class AuthenticationEffects {
  returnUrl: string = '/';

  errorMessage(errorMsg: string) {
    // Swal.fire({
    //   title: 'Oops...',
    //   text: errorMsg,
    //   icon: 'error',
    //   customClass: {
    //     confirmButton: 'text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20',
    //   },
    //   buttonsStyling: true,
    //   showCloseButton: false,
    // }).then(() => {
    //   // location.reload();
    // })

    showAlertMessage(errorMsg ?? "Something went wrong!", "error");
  }

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(login),
      exhaustMap(({ email, password }) => {
        this.ngxSpinnerService.show();
        try {
          this.loginService.login(email, password).subscribe((response: any) => {
            this.ngxSpinnerService.show();
            if (response?.status) {
              this.ngxSpinnerService.hide();
              const redirectUrl = this.route.snapshot.queryParams['returnUrl'] ? atob(this.route.snapshot.queryParams['returnUrl']) : '/';
              const userData = response?.data ?? {};
              localStorage.setItem("token", userData?.token ?? "");
              localStorage.setItem("organizations", userData?.organizations ?? "");
              localStorage.setItem('currentUser', JSON.stringify(userData));
              localStorage.setItem('currentUserDetails', JSON.stringify(userData));
              this.userdataService.setUserData(userData);
              if (userData.isPasswordReseted) {
                this.router.navigate(['/change-password-public']);
              } else {
                //window.location.reload();
                this.router.navigate([redirectUrl]);
              }
              loginSuccess({ user: userData });
            } else {
              this.errorMessage(response?.message ?? "Something went wrong! try again");
              this.ngxSpinnerService.hide();
            }
          }, (error) => {
            this.errorMessage(error ?? "Wrong password! try again");
            this.ngxSpinnerService.hide();
          })
        } catch (error: any) {
          this.errorMessage(error?.message ?? "Something went wrong! try again");
          catchError((error) => of(loginFailure({ error })));
          this.ngxSpinnerService.hide();
        }
        // Handle the case where none of the conditions match
        return of(loginFailure({ error: 'Unsupported authentication method' }));
      })
    )
  );


  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logout),
      tap(() => {
        // Perform any necessary cleanup or side effects before logging out
      }),
      exhaustMap(() => of(logoutSuccess()))
    )
  );

  constructor(
    @Inject(Actions) private actions$: Actions,
    private router: Router,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private ngxSpinnerService: NgxSpinnerService,
    private userdataService: UserdataService
  ) {
  }

}
