<body class="flex min-h-screen lg:py-4 bg-slate-50 dark:bg-zink-800 dark:text-zink-100 font-public">
  <div class="flex w-full p-4 justify-center">
    <div class="flex items-center">
      <div class="mb-0 lg:mx-auto lg:w-[410px] card shadow-none border-none !bg-transparent h-fit">
        <div class="!px-10 !py-12 card-body">
          <div class="flex flex-col items-center justify-center text-center mb-4">
            <span
              class="group-data-[sidebar-size=sm]:hidden font-kanit text-3xl md:text-4xl lg:text-4xl xl:text-4xl font-bold italic text-white mb-6">
              SKETCHPLAY
            </span>

            <ng-container>
              <span class="text-[#94A3B8]">
                Enter the email address associated with your account.
              </span>
            </ng-container>

          </div>

          <ng-container>
            <form action="" class="mt-10" id="forgotPassForm" [formGroup]="forgotPassForm"
              (ngSubmit)="onForgotPassSubmit()">
              <div class="mb-5">
                <input type="text" formControlName="email" autocomplete="off"
                  class="form-control form-input border border-input-border-color bg-input-bg-color color-['#94A3B8']  text-[#94A3B8]  py-3"
                  placeholder="Enter your email..." [readOnly] ="userData.length > 0"  />

                <div *ngIf="
                forgotPassForm.get('email')?.invalid &&
                (forgotPassForm.get('email')?.dirty ||
                  forgotPassForm.get('email')?.touched && isFormSubmitted)
              " class="invalid-feedback">
                  <div *ngIf="forgotPassForm.get('email')?.errors?.['required']" class="text-red-500 ml-1 mt-1">
                    Email is required.
                  </div>
                </div>
                <div *ngIf="
                forgotPassForm.get('email')?.invalid &&
                (forgotPassForm.get('email')?.dirty && isFormSubmitted)
              " class="invalid-feedback">
                  <div *ngIf="forgotPassForm.get('email')?.errors?.['pattern']" class="text-red-500 ml-1 mt-1">
                    Please enter correct email address.
                  </div>
                </div>
              </div>
              <ng-container *ngIf="userData.length > 0">
                <div class="mb-5 forgotSelect">
                  <ng-select
                    [multiple]="false"
                    formControlName="userId"
                    placeholder="Select User"
                    class="text-[#94a3b8]">
                    <ng-option *ngFor="let user of userData" [value]="user.id">
                      {{ user.firstName + ' ' +  user.lastName}}
                    </ng-option>
                  </ng-select>
                  <!-- <ng-select
                    [items]="userData"
                    [multiple]="false"
                    formControlName="userId"
                    bindLabel="name"
                    bindValue="id"
                    placeholder="Select User"
                    class="custom-ng-select w-full"
                    [clearable]="false">
                    <ng-template ng-optgroup-tmp let-item="item">
                      {{ item.firstName + ' ' +  item.lastName}}
                    </ng-template>
                  </ng-select> -->
  
                  <div *ngIf="
                  forgotPassForm.get('userId')?.invalid &&
                  (forgotPassForm.get('userId')?.dirty ||
                    forgotPassForm.get('userId')?.touched && isFormSubmitted)
                " class="invalid-feedback">
                    <div *ngIf="forgotPassForm.get('userId')?.errors?.['required']" class="text-red-500 ml-1 mt-1">
                      Please select user.
                    </div>
                  </div>
                </div>
              </ng-container>
              

              <div class="mt-12">
                <button
                  class="w-full text-[#C9D8E9] font-semibold btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                 Send Password
                </button>

              </div>
              <div class="mt-6 flex justify-center">
                <a (click)="navigateToLogin()" class=" text-[#C9D8E9]">Back to <strong
                    class=" font-semibold underline transition-all cursor-pointer">Login</strong></a>
              </div>
            </form>
          </ng-container>


          <div class="mt-8 text-center">
            <span>

            </span>
            <p class="text-[#C9D8E9] mt-32">
              © 2024 Crafted with
              <i class="ri-heart-fill heart-icon text-[#ff0000]"></i> by Lab3, Interactive, inc.
              <br />
              DBA as
              <a href="https://sketchplay.com" target="_blank"
                class="font-semibold underline transition-all duration-150 ease-linear dark:text-zink-200 dark:hover:text-custom-500">
                Sketchplay.
              </a>
            </p>
          </div>

        </div>
      </div>
    </div>
  </div>
</body>