<form action="" class="mt-10" id="signInForm" formGroup="loginForm">
    <!-- <div class="mb-7 flex space-x-2">
      <div class="relative flex-1">
        <input type="password" formControlName="otp1" id="otp1"
          class="form-input border border-input-border-color rounded bg-input-bg-color h-16 text-[#94A3B8] py-4 w-12" />
      </div>
      <div class="relative flex-1">
        <input type="password" formControlName="otp2" id="otp2"
          class="form-input border border-input-border-color rounded bg-input-bg-color h-16 text-[#94A3B8] py-4 w-12" />
      </div>
      <div class="relative flex-1">
        <input type="text" formControlName="otp3" id="otp3"
          class="form-input border border-input-border-color rounded bg-input-bg-color h-16 text-[#94A3B8] py-4 w-12" maxlength="1"/>
      </div>
      <div class="relative flex-1">
        <input type="text" formControlName="otp4" id="otp4"
          class="form-input border border-input-border-color rounded bg-input-bg-color h-16 text-[#94A3B8] py-4 w-12" maxlength="1" />
      </div>
      <div class="relative flex-1">
        <input type="text" formControlName="otp5" id="otp5"
          class="form-input border border-input-border-color rounded bg-input-bg-color h-16 text-[#94A3B8] py-4 w-12" maxlength="1" />
      </div>
      <div class="relative flex-1">
        <input type="text" formControlName="otp6" id="otp6"
          class="form-input border border-input-border-color rounded bg-input-bg-color h-16 text-[#94A3B8] py-4 w-12"
          maxlength="1"
          />
      </div>
    </div> -->
    <div class="flex justify-center">
      <ng-otp-input class="custom-otp-input" (onInputChange)="onOtpChange($event)" id="otpInput" #ngOtpInput [config]="config"></ng-otp-input>
    </div>

    <div class="otp-controls mt-2 !justify-end">
      <!-- Countdown timer on the left -->
      <p  class="countdown text-[#94A3B8]" *ngIf="isResetDisabled">Reset OTP in {{ countdown }} seconds</p>
      <!-- Reset OTP button on the right -->
      <button type="button" [disabled]="isResetDisabled" (click)="resetOtp();" [ngClass]="isResetDisabled ? 'reset-button':'cursor-pointer reset-button text-[#94A3B8]' ">
        Resend OTP
      </button>
    </div>

    <div class="mt-32" >
      <button [disabled]="!isOtpCorrect"
        class="w-full text-[#C9D8E9] font-normal btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
        (click)="navigateToLogin($event)"
        
        >
        {{ submitted ? 'Loading...' : 'Complete Registration' }}
        
      </button>
    </div>
  </form>