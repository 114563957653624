import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { formatPhoneNumber, showAlertMessage } from '@app/core/helpers/utils';
import { AuthenticationService } from '@app/core/services/auth.service';
import { NgxMaskDirective } from 'ngx-mask';
import { SignUpStep1Component } from './step1/sign-up-step1/sign-up-step1.component';
import { SignUpStep2Component } from './step2/sign-up-step2/sign-up-step2.component';
import { SignUpStep3Component } from './step3/sign-up-step3/sign-up-step3.component';

interface signUpDetails {
  firstName: string;
  lastName: string;
  email: string;
  mobile: number | string;
}

@Component({
  selector: 'app-signup',
  standalone: true,
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  imports: [
    SignUpStep1Component,
    SignUpStep2Component,
    SignUpStep3Component,
    ReactiveFormsModule,
    CommonModule,
    NgxMaskDirective,
  ],
})
export class SignupComponent implements OnInit {
  signUpForm: FormGroup = new FormGroup({});

  detail?: signUpDetails;
  steps: number = 0;
  selectedRole: string = '';
  dropSpecialCharacters: boolean = true;
  userDetail: any;
  otpValueOnNext: any;
  submitted: boolean = false;
  isFormSubmitted: boolean = false;
  isTermsandCondition: boolean = false;
  showErrorofTerms: boolean = false;

  constructor(private router: Router, private authService: AuthenticationService) { }

  ngOnInit() {
    this.initForm();
  }
  initForm() {
    this.signUpForm = new FormGroup({
      firstName: new FormControl(this.detail?.firstName.trim(), [
        Validators.required,
        Validators.pattern(/\S.*/),
      ]),
      lastName: new FormControl(this.detail?.lastName.trim(), [
        Validators.required,
        Validators.pattern(/\S.*/),
      ]),
      email: new FormControl(this.detail?.email.trim(), [
        Validators.required,
        Validators.pattern(/^\s*[\w-]+(\.[\w-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,})\s*$/)
      ]),
      mobile: new FormControl(this.detail?.mobile, [
        Validators.required,
        Validators.pattern(/^\(\d{3}\) \d{3}-\d{4}$/)
      ]),
    });
  }
  get f(): { [key: string]: AbstractControl; } {
    return this.signUpForm.controls;
  }
  get accessCode() {
    return this.signUpForm && this.signUpForm.get('email')!;
  }

  navigateToLogin() {
    this.router.navigate(['/account-login']);
  }
  goToStep1() {
    this.isFormSubmitted = true;
    if (this.signUpForm.invalid) {
      this.signUpForm.markAllAsTouched();
      return;
    }
    if (!this.isTermsandCondition) {
      this.showErrorofTerms = true;
      return;
    }
    this.submitted = true;
    const formData = this.signUpForm.value;
    localStorage.setItem('Email', formData.email)
    const mobile = formatPhoneNumber(formData.mobile);
    const firstNameTrimmedValue = this.signUpForm.get('firstName')?.value.trim();
    const lastNameTrimmedValue = this.signUpForm.get('lastName')?.value.trim();
    const emailTrimmedValue = this.signUpForm.get('email')?.value.trim();
    this.userDetail = {
      ...formData,
      mobile: mobile,
      firstName: firstNameTrimmedValue,
      lastName: lastNameTrimmedValue,
      email: emailTrimmedValue
    }
    let formattedPhoneNumber = formatPhoneNumber(formData.mobile);
    let trimmedEmailValue = formData.email.trim();

    let checkEmail = {
      email: trimmedEmailValue,
      countryCode: '+1',
      dialCode: "US",
      mobile: formattedPhoneNumber
    }
    this.onRegister(checkEmail);
  }

  onRegister(userData: any) {
    this.authService.checkEmail(userData).subscribe(
      (response: any) => {
        if (response.status) {
          showAlertMessage(response?.message, "success", () => {
            this.steps = 1;
            this.submitted = false;
          })
        }
      },
      (response) => {
        this.submitted = false;
        showAlertMessage(response ?? "Something went wrong!", "error")
      }
    )
  }
  goToStep2() {
    this.steps = 2;
  }
  goToStep3() {
    this.steps = 3;
  }
  selectRole(role: string) {
    this.selectedRole = role;
  }

  isSelected(role: string): boolean {
    return this.selectedRole === role;
  }
  step(value: any) {
    this.steps = value;
  }
  onCheckboxChange(event: any) {
    let checked = event.target.checked
    if (checked) {
      this.showErrorofTerms = false;
    } else if (this.isFormSubmitted && !checked) {
      this.showErrorofTerms = true;
    }
    this.isTermsandCondition = checked;
  }

  onSubmit() {
    const formData = this.signUpForm.value;
  }
}