<body
    class="flex min-h-screen bg-slate-50 dark:bg-zink-800 dark:text-zink-100 font-public">
    <div class="flex w-full justify-center">
        <div class="flex items-center">
            <div
                class="mb-0 lg:mx-auto w-[420px] sm:w-[510px] card shadow-none border-none !bg-transparent h-fit">
                <div class="!px-10 !py-12 card-body">
                    <div
                        class="flex flex-col items-center justify-center text-center mb-4">
                        <span
                            class="group-data-[sidebar-size=sm]:hidden font-kanit text-3xl md:text-4xl lg:text-4xl xl:text-4xl font-bold italic text-white mb-6">
                            Complete Payment
                        </span>
                        <ng-container *ngIf="step === 1">
                            <div class="w-full">
                                <app-make-league-payment
                                     [leaguePaymentData]="leaguePaymentData"
                                     (goToStep)="goToStep($event)"
                                     (setStepData)="setStepData($event)"
                                ></app-make-league-payment>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="step === 2">
                            <div class="w-full">
                                <app-league-card-payment
                                ></app-league-card-payment>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</body>
