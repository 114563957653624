import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { LUCIDE_ICONS, LucideAngularModule, LucideIconProvider, icons } from 'lucide-angular';
import { AuthMainAuthenticationService } from '../../../../core/services/auth-main.service';
import { AuthenticationService } from '../../../../core/services/auth.service';

@Component({
  selector: 'app-logout-basic',
  standalone: true,
  imports: [RouterModule, LucideAngularModule],
  templateUrl: './logout-basic.component.html',
  styleUrl: './logout-basic.component.scss',
  providers: [{ provide: LUCIDE_ICONS, multi: true, useValue: new LucideIconProvider(icons) }]
})
export class LogoutBasicComponent {

  constructor(private authService: AuthenticationService, public router: Router, private authMainservice: AuthMainAuthenticationService) { }
  /**
  * Logout the user
  */
  logout() {
    this.authMainservice.logout();
    this.router.navigate(['/account-login']);
  }
}
