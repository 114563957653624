<div class="px-2 md:px-12 text-black rounded-md dark:text-white">
    <div class="mx-auto w-full">
          @if (stripe && elementsOptions.clientSecret && stripePaymentObj) {
            <ngx-stripe-elements
              [stripe]="stripe"
              [elementsOptions]="elementsOptions">
              <ngx-stripe-payment
                [options]="paymentElementOptions"
                class="payment-element" />
            </ngx-stripe-elements>
          }
          <button
  
            type="submit"
            [disabled]="isLoading"
            (click)="pay()"
            class="w-full mt-6 text-[#C9D8E9] font-normal btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20 ng-star-inserted">
            Complete Payment ({{
              stripePaymentObj?.totalCost | formatCurrency
            }})
          </button>
    </div>
  </div>
  