<div
  class="fixed items-center hidden bottom-6 right-12 h-header group-data-[navbar=hidden]:flex">
  <button
    [DrawerTrigger]="'customizerButton'"
    type="button"
    class="inline-flex items-center justify-center size-12 p-0 transition-all duration-200 ease-linear rounded-md shadow-lg text-sky-50 bg-sky-500">
    <lucide-angular
      name="settings"
      [class]="'inline-block size-5'"></lucide-angular>
  </button>
</div>

<drawer id="customizerButton" [position]="'drawer-end'">
  <div
    class="flex justify-between p-4 border-b border-slate-200 dark:border-zink-500">
    <div class="grow">
      <h5 class="mb-1 text-16"></h5>
      <p class="font-normal text-slate-500 dark:text-zink-200"></p>
    </div>
  </div>
</drawer>
