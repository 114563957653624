<div
    class="w-full mb-4 border-b border-[rgb(226,232,240)] dark:border-[#29415F] pb-4">
    <div
        class="rounded-md dark:bg-[#29415F] bg-[#f3f6f9] dark:text-[#C9D8E9]">
        <div
            class="flex justify-between py-3 px-5 border-b dark:border-[#000000A6] ctmBorder first">
            <p>{{leaguePaymentData?.isCustomFee ? 'Total Fee' : 'Team Fee'}}</p>
            <span> {{ leaguePaymentData?.teamFee |
                formatCurrency }}
            </span>
        </div>
        <div
            class="flex justify-between py-3 px-5 border-b dark:border-[#000000A6] ctmBorder" *ngIf="!leaguePaymentData?.isCustomFee" >
            <p>Player Fee
                ({{leaguePaymentData?.totalAmountPerRoster|
                formatCurrency}} *
                {{leaguePaymentData?.totalRosterCount ??
                0}})</p>
            <span> {{
                leaguePaymentData?.totalRoster |
                formatCurrency }} </span>
        </div>
        <div class="flex justify-between py-3 px-5 ctmBorder last">
            <p>Processing & Platform Fee</p>
            <span> {{ leaguePaymentData?.serviceFee |
                formatCurrency
                }}</span>
        </div>
    </div>
</div>

<div
    class="w-full flex justify-between mt-3 dark:bg-[#29415F] bg-[#f3f6f9] dark:text-[#C9D8E9] py-3 px-5 rounded-md ctmBorder">
    <p class="font-bold">Total being charged:</p>
    <span> {{
        leaguePaymentData?.totalCost |
        formatCurrency }}</span>
</div>
<button
    type="button"
    class="w-full mt-6 text-[#C9D8E9] font-normal btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20 ng-star-inserted"
    (click)="teamPaymentIntent()">
    Confirm Checkout
</button>