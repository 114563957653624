import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';


// Auth Services
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/auth.service';
import { LocalStorageService } from '../services/local-storage.service';
import { TokenStorageService } from '../services/token-storage.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private tokenStorageService: TokenStorageService, private route: ActivatedRoute, private location: Location, private authenticationService: AuthenticationService,
    private localStorageService: LocalStorageService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUrl = this.location.path();
    const queryString = currentUrl?.split('?')[1];
    const urlSearchParams = new URLSearchParams(queryString);
    const urlToken = urlSearchParams.get('token');
    const urlFrom = urlSearchParams.get('from');
    const urlEventId = urlSearchParams.get('eventId');
    const urlEventType = urlSearchParams.get('eventType');
    if (urlToken) {
      localStorage.setItem('token', urlToken);
      localStorage.setItem('urlFrom', urlFrom ? urlFrom : "");
      return new Observable<boolean>((observer) => {
        this.authenticationService.userMe().subscribe(
          (response: any) => {
            const userData = response?.data ?? {};
            localStorage.setItem('organizations', userData?.organizations ?? '');
            localStorage.setItem('currentUser', JSON.stringify(userData));
            observer.next(true);
            observer.complete();
            if (urlEventId) {
              if (urlEventType === 'camp') {
                this.router.navigate(['camps'], { queryParams: { eventId: urlEventId } })
                this.localStorageService.saveSingleKey('activeLink', 'Camps');
              } else if (urlEventType === 'clinic') {
                this.router.navigate(['clinics'], { queryParams: { eventId: urlEventId } })
                this.localStorageService.saveSingleKey('activeLink', 'Clinics & Showcases');
              } else {
                this.router.navigate(['dailytraining'], { queryParams: { eventId: urlEventId } })
                this.localStorageService.saveSingleKey('activeLink', 'Daily Training');
              }
            }
          },
          (error) => {
            this.router.navigate(['/account-login']);
            observer.error(error);
          }
        );
      });
    } else {
      return this.checkAuthenticated(state)
    }
  }

  private checkAuthenticated(state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (this.tokenStorageService.getToken()) {
      return true;
    } else {
      const rtnUrl = btoa(state.url)
      this.router.navigate(['/account-login'], { queryParams: { returnUrl: rtnUrl } });
      return false;
    }
  }
}
