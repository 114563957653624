import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@app/core/services/auth.service';
import { LocalStorageService } from '@app/core/services/local-storage.service';
import { login } from '@app/store/Authentication/authentication.actions';
import { Store } from '@ngrx/store';
import { LUCIDE_ICONS, LucideAngularModule, LucideIconProvider, icons } from 'lucide-angular';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [LucideAngularModule, FormsModule, ReactiveFormsModule, CommonModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
  styles: ``,
  providers: [{ provide: LUCIDE_ICONS, multi: true, useValue: new LucideIconProvider(icons) }]

})
export class LoginComponent {
  // Login Form
  loginForm!: UntypedFormGroup;
  submitted = false;
  fieldTextType!: boolean;
  error = '';
  returnUrl!: string;
  a: any = 10;
  b: any = 20;
  toast!: false;
  isFormSubmitted: boolean = false;
  urlToken: any = '';
  urlFrom: any = ''

  // set the current year
  year: number = new Date().getFullYear();
  rememberMe: boolean = false;

  // tslint:disable-next-line: max-line-length
  constructor(private formBuilder: UntypedFormBuilder,
    private router: Router,
    private store: Store,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService
  ) {
    if (this.authenticationService.user) {
      this.router.navigate(['/']);
    }
  }
  ngOnInit(): void {
    if (localStorage.getItem('currentUser')) {
      this.router.navigate(['/']);
    }
    // const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(/^[\w-]+(\.[\w-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,})$/)]],
      password: ['', [Validators.required]],
    });
    const savedCredentials = JSON.parse(localStorage.getItem('userCredentials') || '{}');
    if (savedCredentials?.rememberMe) {
      this.loginForm?.patchValue({
        email: savedCredentials.email,
        password: savedCredentials.password,
      });
      this.rememberMe = savedCredentials?.rememberMe
    }
  }

  navigateToSignUp() {
    this.router.navigate(['/sign-up']);
  }

  routeToForgotPass() {
    this.router.navigate(['/forgot-password']);
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  /**
   * Form submit
   */
  onSubmit() {
    this.isFormSubmitted = true;
    this.loginForm.markAllAsTouched();
    if (this.loginForm.invalid) {
      return;
    }
    this.submitted = true;

    const email = this.f['email'].value;
    const password = this.f['password'].value;
    // Login Api
    this.store.dispatch(login({ email: email, password: password }));
    this.localStorageService.saveData('initialRender', 1);
    if (this.rememberMe) {
      localStorage.setItem('userCredentials', JSON.stringify({
        email: this.f['email'].value,
        password: this.f['password'].value,
        rememberMe: this.rememberMe
      }));
    } else {
      localStorage.removeItem('userCredentials');
    }
  }

  /**
   * Password Hide/Show
   */
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  onKeepMeLogIn(event: any) {
    this.rememberMe = event?.target?.checked || false;
  }
}
