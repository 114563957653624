<body
  class="flex min-h-[100vh] bg-slate-50 dark:bg-zink-800 dark:text-zink-100 font-public">
  <div class="flex w-full justify-center">
    <div class="flex items-center">
      <div
        class="mb-0 lg:mx-auto w-full sm:w-[600px] card shadow-none border-none bg-transparent h-fit">
        <div class="!px-10 !py-12 card-body">
          <div
            class="flex flex-col items-center justify-center text-center">
            <span
            class="group-data-[sidebar-size=sm]:hidden font-kanit text-3xl md:text-4xl lg:text-4xl xl:text-4xl font-bold italic text-white mb-6">
            SKETCHPLAY
          </span>
            <span class="text-[#94A3B8] text-[14px] mx-8">
              Choose a password below. We recommend you use a combination of
              letters, numbers, symbols, and ensure it is at least 8 characters
              long.
            </span>
            <form
              action=""
              class="mt-10 w-full"
              id="changePassForm"
              [formGroup]="changePassForm"
              (ngSubmit)="onSubmit()">
              <div>
                <div class="mb-6">
                  <div class="relative">
                    <input
                      type="{{ hidePasswordField ? 'password' : 'text' }}"
                      formControlName="currentPassword"
                      id="currentPassword"
                      autocomplete="off"
                      class="form-input border border-solid border-[#284365] rounded bg-input-bg-color text-[#94A3B8] text-[12px] py-3"
                      placeholder="Type your old password here…"
                      autocomplete="off"
                      [attr.aria-label]="
                        hidePasswordField ? 'Show password' : 'Hide password'
                      " />
                    <i
                      class="ri-eye-off-fill text-lg absolute top-0 bottom-0 right-4 flex items-center"
                      (click)="togglePasswordVisibility()"
                      *ngIf="hidePasswordField"></i>
                    <i
                      class="ri-eye-fill text-lg absolute top-0 bottom-0 right-4 flex items-center"
                      (click)="togglePasswordVisibility()"
                      *ngIf="!hidePasswordField"></i>
                  </div>
                  <div
                      *ngIf="
                        changePassForm.get('currentPassword')?.invalid &&
                        (changePassForm.get('currentPassword')?.dirty ||
                          changePassForm.get('currentPassword')?.touched)
                      "
                      class="invalid-feedback">
                      <div
                        *ngIf="
                          changePassForm.get('currentPassword')?.errors?.[
                            'required'
                          ]
                        "
                        class="text-red-500 ml-1 mt-1 text-left">
                        Old password is required.
                      </div>
                  </div>
                </div>

                <div class="mb-6">
                  <div class="relative">
                  <input
                    type="{{ hideNewPasswordField ? 'password' : 'text' }}"
                    formControlName="password"
                    id="password"
                    autocomplete="off"
                    class="form-input border border-solid border-[#284365] rounded bg-input-bg-color text-[#94A3B8] text-[12px] py-3"
                    placeholder="Type your password here…"
                    autocomplete="off"
                    [attr.aria-label]="
                      hideNewPasswordField ? 'Show password' : 'Hide password'
                    " />
                  <i
                    class="ri-eye-off-fill text-lg absolute top-0 bottom-0 right-4 flex items-center"
                    (click)="togglePasswordVisibilityforNewPass()"
                    *ngIf="hideNewPasswordField"></i>
                  <i
                    class="ri-eye-fill text-lg absolute top-0 bottom-0 right-4 flex items-center"
                    (click)="togglePasswordVisibilityforNewPass()"
                    *ngIf="!hideNewPasswordField"></i>
                  </div>
                  <div
                    *ngIf="
                      changePassForm.get('password')?.invalid &&
                      (changePassForm.get('password')?.dirty ||
                        changePassForm.get('password')?.touched)
                    "
                    class="invalid-feedback text-left">
                    <div
                      *ngIf="
                        changePassForm.get('password')?.errors?.['required']
                      "
                      class="text-red-500 ml-1 mt-1">
                      Password is required.
                    </div>
                    <div
                      *ngIf="
                        changePassForm.get('password')?.invalid &&
                        changePassForm.get('password')?.dirty &&
                        isFormSubmitted
                      "
                      class="invalid-feedback">
                      <div
                        *ngIf="
                          changePassForm.get('password')?.errors?.['pattern']
                        "
                        class="text-red-500 ml-1 mt-1">
                        Please enter correct password.
                      </div>
                    </div>
                  </div>
                </div>

                <div class="mb-6">
                  <div>
                    <div class="relative">
                      <input
                        type="{{ hideConfirmField ? 'password' : 'text' }}"
                        formControlName="confirmPassword"
                        id="confirmPassword"
                        autocomplete="off"
                        class="form-input border border-solid border-[#284365] rounded bg-input-bg-color text-[#94A3B8] text-[12px] py-3"
                        placeholder="Confirm your password here…"
                        [attr.aria-label]="
                          hideConfirmField ? 'Show password' : 'Hide password'
                        " />

                      <i
                        class="ri-eye-off-fill text-lg absolute top-0 bottom-0 right-4 flex items-center"
                        (click)="toggleConfirmVisibility()"
                        *ngIf="hideConfirmField"></i>
                      <i
                        class="ri-eye-fill text-lg absolute top-0 bottom-0 right-4 flex items-center"
                        (click)="toggleConfirmVisibility()"
                        *ngIf="!hideConfirmField"></i>
                    </div>

                    <div
                      *ngIf="
                        changePassForm.get('confirmPassword')?.invalid &&
                        (changePassForm.get('confirmPassword')?.dirty ||
                          (changePassForm.get('confirmPassword')?.touched))
                      "
                      class="invalid-feedback text-left">
                      <div
                        *ngIf="
                          changePassForm.get('confirmPassword')?.errors?.[
                            'required'
                          ]
                        "
                        class="text-red-500 ml-1 mt-1">
                        Confirm password is required.
                      </div>
                      <div
                        *ngIf="
                          changePassForm
                            .get('confirmPassword')
                            ?.hasError('confirmPasswordValidator')
                        "
                        class="text-red-500 ml-1 mt-1">
                        Password & Confirm Password do not match.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-10">
                <button
                  type="submit"
                  [disabled]="isFormSubmitted"
                  class="w-full text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                  {{ isFormSubmitted ? "Loading..." : "Change Password" }}
                </button>
              </div>
            </form>
            <div class="mt-8 text-center">
                  <p class="mb-7 text-[12px] text-[#C9D8E9]">
                    <a (click)="navigateToLogin()"
                      class="font-bold transition-all cursor-pointer duration-150 ease-linear text-[#C9D8E9]">Back to login</a>
                  </p>
                  </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</body>
