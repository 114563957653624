import { CommonModule, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { showAlertMessage } from '@app/core/helpers/utils';
import { AuthenticationService } from '@app/core/services/auth.service';
import { TokenStorageService } from '@app/core/services/token-storage.service';

@Component({
  selector: 'app-change-password-public',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule],
  templateUrl: './change-password-public.component.html',
  styleUrl: './change-password-public.component.scss'
})
export class ChangePasswordPublicComponent implements OnInit {
  constructor(private fb: FormBuilder, private authService: AuthenticationService, private router: Router,
    private location: Location, private tokenStorageService: TokenStorageService) { }

  ngOnInit(): void {
    this.initForm();

    this.location.subscribe(() => {
      this.tokenStorageService.signOut();
      this.router.navigate(['/account-login']);
    });
  }
  navigateToLogin() {
    this.tokenStorageService.signOut();
    this.router.navigate(['/account-login']);
  }

  changePassForm: FormGroup = new FormGroup({});
  hidePasswordField: boolean = true;
  hideConfirmField: boolean = true;
  hideNewPasswordField: boolean = true;
  isFormSubmitted: boolean = false;


  initForm() {
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;

    this.changePassForm = this.fb.group({
      currentPassword: ["", [Validators.required]],
      password: ["", [Validators.required, Validators.pattern(passwordPattern)]],
      confirmPassword: ["", [Validators.required]]
    }, { validator: this.ConfirmPasswordValidator('password', 'confirmPassword') });
  }

  ConfirmPasswordValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      let control = formGroup.controls[controlName];
      let matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors?.['confirmPasswordValidator']) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmPasswordValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  togglePasswordVisibility() {
    this.hidePasswordField = !this.hidePasswordField;
  }

  togglePasswordVisibilityforNewPass() {
    this.hideNewPasswordField = !this.hideNewPasswordField;
  }
  toggleConfirmVisibility() {
    this.hideConfirmField = !this.hideConfirmField;
  }

  onSubmit(): void {
    this.changePassForm.markAllAsTouched();
    this.isFormSubmitted = true;
    const oldPassword = this.changePassForm.controls['currentPassword'].value;
    const newPassword = this.changePassForm.controls['password'].value;
    if (this.changePassForm.invalid) {
      this.isFormSubmitted = false;
      return;
    } else {
      this.authService.changePassword({
        oldPassword: oldPassword ?? "",
        newPassword: newPassword ?? ""
      }).subscribe(
        (response: any) => {
          this.isFormSubmitted = false;
          if (response.status) {
            showAlertMessage(response.message ?? "Success", "success", () => this.router.navigate(['/dashboard']));
          }
        },
        (error) => {
          this.isFormSubmitted = false;
          showAlertMessage(error ?? "Something went wrong!", "error");
        }
      );
    }
  }


}
