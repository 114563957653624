import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { CustomDrawerComponent, DrawerModule } from '@app/shared/component/drawer';

@Component({
  selector: 'app-bottom-popup',
  standalone: true,
  imports: [CommonModule, DrawerModule],
  templateUrl: './bottom-popup.component.html',
  styleUrl: './bottom-popup.component.scss'
})
export class BottomPopupComponent {

  isVisible = false;
  buttonText = 'Download App';
  appLink!: string;
  schemeUrl!: string;

  @ViewChild('bottomDrawer') bottomDrawer: CustomDrawerComponent | any;

  ngAfterViewInit() {
    const os = this.getMobileOperatingSystem();
    if (os !== 'unknown') {
      this.bottomDrawer.open();
      window.document.body.classList.add('ctmno-scroll');
      if (os === 'android') {
        this.appLink = 'https://play.google.com/store/apps/details?id=com.lab3.sketchplay';
        this.schemeUrl = 'intent://#Intent;scheme=sketchplay;package=com.lab3.sketchplay;end';
      } else if (os === 'ios') {
        this.appLink = 'https://apps.apple.com/us/app/sketchplay/id1606746902';
        this.schemeUrl = 'sketch://';
      }
    } else {
      this.isVisible = false;
    }
  }

  getMobileOperatingSystem(): string {
    const userAgent = navigator.userAgent || navigator.vendor;
    const platform = navigator.platform || '';

    if (/android/i.test(userAgent)) {
      return "android";
    }
    if (/iPad|iPhone|iPod/.test(userAgent) ||
      (platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
      return 'ios';
    }

    // Additional check for iPadOS in case it's detected as Mac
    if (/Macintosh/.test(userAgent) && 'ontouchend' in document) {
      return 'ios';
    }

    return "unknown";
  }

  handleButtonClick() {
    // Assuming a scheme URL that opens the app if installed
    window.location.href = this.schemeUrl;

    setTimeout(() => {
      window.location.href = this.appLink;
    }, 250);
  }

  closeDrawer() {
    window.document.body.classList.remove('ctmno-scroll');
    this.bottomDrawer.close();
  }

}
