import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-sign-up-step3',
  templateUrl: './sign-up-step3.component.html',
  standalone: true,
  imports: [],
  styleUrls: ['./sign-up-step3.component.css']
})
export class SignUpStep3Component implements OnInit {
  @Output() stepValue = new EventEmitter<any>();

  selectedRole: any;

  constructor() { }

  ngOnInit() {
  }
  selectRole(role: string) {
    this.selectedRole = role;
  }
  isSelected(role: string): boolean {
    return this.selectedRole === role;
  }
  emitStep(step: number) {
    this.stepValue.emit(step);
  }

}
