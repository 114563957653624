<form action="" class="mt-10" id="signInForm" formGroup="loginForm">
  <div class="mb-7 flex space-x-4 items-center justify-center">
    <div class="relative">
      <div (click)="selectRole('parent')" [class.selected]="isSelected('parent')"
        class="form-div border border-none bg-input-bg-color text-[#94A3B8] py-4 h-28 w-24 rounded text-center cursor-pointer flex items-center justify-center">
        Parent
      </div>
    </div>
    <div class="relative">
      <div (click)="selectRole('athlete')" [class.selected]="isSelected('athlete')"
        class="form-div border border-none bg-input-bg-color text-[#94A3B8] py-4 h-28 w-24 rounded text-center cursor-pointer flex items-center justify-center">
        Athlete
      </div>
    </div>
    <div class="relative">
      <div (click)="selectRole('coach')" [class.selected]="isSelected('coach')"
        class="form-div border border-none bg-input-bg-color text-[#94A3B8] py-4 h-28 w-24 rounded text-center cursor-pointer flex items-center justify-center">
        Coach
      </div>
    </div>
  </div>
  <div class="mt-32">
    <button
      class="w-full text-[#C9D8E9] font-normal btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
      Complete Registration</button>
  </div>
</form>