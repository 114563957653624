import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));


let pathname = window.location.pathname;
let notRemoveData = pathname === "/create-event" || pathname === "/event-details" || pathname === "/create-event-details";

if (!notRemoveData) {
  localStorage.removeItem('StoredData');
}
