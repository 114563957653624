import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ClickControlService } from './layouts/sidebar/clickControlService';
import { BottomPopupComponent } from './shared/component/bottom-popup/bottom-popup.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, NgxSpinnerModule, BottomPopupComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  routeName: string = '';

  constructor(
    private globalClickControlService: ClickControlService,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }
  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.routeName = this.getCurrentRouteName(this.route);
        // if (this.routeName === 'create-event') {
        //   this.globalClickControlService.setClickDisabled(true);
        // } else if (this.routeName === 'profile') {
        //   this.globalClickControlService.setClickDisabled(true);
        // } else if (this.routeName === 'member') {
        //   this.globalClickControlService.setClickDisabled(true);
        // } else if (this.routeName === 'create-event-details') {
        //   this.globalClickControlService.setClickDisabled(true);
        // } else if (this.routeName === 'event-details') {
        //   this.globalClickControlService.setClickDisabled(true);
        // } else {
        //  this.globalClickControlService.setClickDisabled(false);
        // }
      }
    });
  }

  private getCurrentRouteName(route: ActivatedRoute): string {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route.routeConfig?.path || '';
  }

  // isPointerEventsDisabled() {
  //   return this.globalClickControlService.isClickDisabled();
  // }

  // @HostListener('document:click', ['$event'])
  // onClick(event: MouseEvent) {
  //   this.globalClickControlService.setClickDisabled(false);
  // }
}
