import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { showAlertMessage } from '@app/core/helpers/utils';
import { PipesModule } from '@app/core/pipes/pipes.module';
import { LeagueService } from '@app/core/services/league.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LeagueCardPaymentComponent } from './league-card-payment/league-card-payment.component';
import { MakeLeaguePaymentComponent } from './make-league-payment/make-league-payment.component';

@Component({
  selector: 'app-league-invoice-payment',
  standalone: true,
  imports: [PipesModule, CommonModule, MakeLeaguePaymentComponent, LeagueCardPaymentComponent],
  templateUrl: './league-invoice-payment.component.html',
  styleUrl: './league-invoice-payment.component.scss'
})
export class LeagueInvoicePaymentComponent {
  formData: any = {}
  leaguePaymentData: any;
  paymentId: string = ''
  step: number = 1

  constructor(private leagueService: LeagueService,
    private ngxSpinnerService: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.paymentId = this.route.snapshot.queryParamMap.get('paymentId') ?? '';
    if (this.paymentId) {
      this.getLeaguePaymentData()
    }
  }

  getLeaguePaymentData() {
    this.ngxSpinnerService.show();
    const reqPayload = {
      hash: this.paymentId
    }
    this.leagueService.getLeaguePaymentData(reqPayload).subscribe((response: any) => {
      this.ngxSpinnerService.hide();
      if (response?.status) {
        this.leaguePaymentData = response?.data;
      }
    }, (error) => {
      this.ngxSpinnerService.hide();
      showAlertMessage(error ?? "Something went wrong!", "error", () => this.router.navigate(['/account-login']));
    });
  }

  goToStep(step: number) {
    this.step = step
  }

  setStepData(data: any) {
    //for step1 data only
    this.formData = data
    this.step = 2
  }

}
