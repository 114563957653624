<drawer #bottomDrawer id="bottomDrawer" [position]="'drawer-bottom'" [isBottomDrawer]="true">
  <div class="h-full p-6">
      <div class="card-body">
          <h4 class="mb-4 !text-black font-bold">Sketchplay is Better on our Mobile App.</h4>
          <h6 class="!text-black">Please download the Sketchplay mobile app to engage with a much better experience.</h6>
          <div class="flex justify-between mt-7">
            <div class="flex items-center">
            <img class="max-h-8 max-w-8" src="/assets/images/favicon/favicon-32x32.png" />
            <h5 class="!text-black ml-2 font-semibold">Sketchplay</h5>
            </div>
            <button (click)="handleButtonClick()">Download App</button>
          </div>
          <div class="flex justify-center mt-8">
            <button (click)="closeDrawer()">Continue on the Web</button>
          </div>
      </div>
  </div>
</drawer>