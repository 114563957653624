import { Routes } from '@angular/router';
import { LogoutBasicComponent } from './account/auth/logout/logout-basic/logout-basic.component';
import { ForgotPasswordComponent } from './account/forgot-password/forgot-password/forgot-password.component';
import { LoginComponent } from './account/login/login.component';
import { SignupComponent } from './account/signup/signup.component';
import { AuthGuard } from './core/guards/auth.guard';
import { PublicAuthGuard } from './core/guards/public-auth.guard';
// import { ComingSoonComponent } from './extrapages/coming-soon/coming-soon.component';
import { Error404Component } from './extrapages/error404/error404.component';
// import { MaintenanceComponent } from './extrapages/maintenance/maintenance.component';
// import { OfflineComponent } from './extrapages/offline/offline.component';
import { ChangePasswordPublicComponent } from './account/auth/change-password-public/change-password-public.component';
import { LayoutComponent } from './layouts/layout/layout.component';
import { LeagueInvoicePaymentComponent } from './pages/league-invoice-payment/league-invoice-payment.component';

export const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    loadChildren: () =>
      import('./pages/pages.route').then(mod => mod.PAGE_ROUTES),
    canActivate: [AuthGuard],
  },

  // Auth
  { path: 'account-login', component: LoginComponent, canActivate: [PublicAuthGuard], },
  { path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [PublicAuthGuard], },
  { path: 'sign-up', component: SignupComponent, canActivate: [PublicAuthGuard], },
  { path: 'auth-logout-basic', component: LogoutBasicComponent, canActivate: [PublicAuthGuard], },
  { path: 'change-password-public', component: ChangePasswordPublicComponent, canActivate: [AuthGuard] },
  { path: 'league-payment', component: LeagueInvoicePaymentComponent },

  // extrapages
  // { path: 'pages-coming-soon', component: ComingSoonComponent, },
  // { path: 'pages-maintenance', component: MaintenanceComponent, },
  { path: '**', component: Error404Component, },
  // { path: 'pages-offline', component: OfflineComponent, },
];
